import styled from "styled-components";
import PageHeader from "../../../Components/Header/PageHeader";
import { Separator } from "../../../Components/StyledComponents";
import StudentList from "../../../Containers/Students/StudentList";
import { useEffect, useState } from "react";
import { Student } from "../../../Types/DataTypes";
import { useApi } from "../../../utils/RequestUtils";
import { useAppSelector } from "../../../hooks/ReduxHooks";

function Students() {
  const [students, setStudents] = useState<Student[]>([]);
  const user = useAppSelector((state) => state.UserReducer);
  const { request } = useApi();
  useEffect(() => {
    request(`/user/${user.id}/student`, "GET").then((res) => {
      setStudents(res);
    });
  }, []);
  return (
    <Container>
      <PageHeader title="Students" navStack={[]} />
      <Separator space={50} />
      <StudentList students={students} />
    </Container>
  );
}

export default Students;

const Container = styled.div`
  padding: 30px;
`;

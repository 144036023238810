import {
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  BriefcaseIcon,
} from "@heroicons/react/20/solid";
import Avatar from "../Avatars/Avatar";

interface ChildHeaderProps {
  profilepic: string | undefined;
  name: string;
  parent: string;
  relation: string;
  email: string;
  phone: string;
  caseNum: string;
  onExport: () => void;
}

function ChildHeader(props: ChildHeaderProps) {
  return (
    <div className="flex lg:flex-row flex-col items-center">
      <div className="mr-5 lg:mb-4">
        <Avatar profilepic={props.profilepic!} />
      </div>

      <div className="flex flex-col justify-center">
        <h2 className="font-bold tracking-tight text-gray-900 sm:text-3xl mb-3">
          {props.name}
        </h2>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4 text-gray-500 font-medium text-sm">
          <span className="mr-4 flex flex-row items-center lg:justify-center">
            <UserIcon className="mr-1" width={16} height={16} />
            {`${props.parent}(${props.relation})`}
          </span>
          <span className="mr-4 flex flex-row items-center">
            <EnvelopeIcon className="mr-1" width={16} height={16} />
            {props.email}
          </span>
          <span className="mr-4 flex flex-row items-center">
            <PhoneIcon className="mr-1" width={16} height={16} /> {props.phone}
          </span>
          <span className="mr-4 flex flex-row items-center">
            <BriefcaseIcon className="mr-1" width={16} height={16} />
            {props.caseNum}
          </span>
        </div>
      </div>
      <div className="flex flex-1 justify-center lg:justify-end">
        <button
          onClick={props.onExport}
          type="button"
          className="h-10 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Export PDF
        </button>
      </div>
    </div>
  );
}

export default ChildHeader;

interface StatusProp {
  type: "active" | "inactive";
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function Status(props: StatusProp) {
  const statusTypeMap = {
    active: "Active",
    inactive: "Inactive",
  };
  return (
    <span
      className={classNames(
        props.type === "active"
          ? "bg-green-100 text-green-800"
          : "bg-red-100 text-red-800",
        "inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium "
      )}
    >
      {statusTypeMap[props.type]}
    </span>
  );
}

export default Status;

import Checkins from "../../Components/Checkins/Checkins";
import { EmployeeActivity } from "../../Types/DataTypes";

interface RecentActivityProps {
  items: EmployeeActivity[];
}

function RecentActivity(props: RecentActivityProps) {
  return (
    <>
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-400 sm:truncate sm:text-base sm:tracking-tight mt-3 mb-3">
          Recent Activity
        </h2>
      </div>
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {props.items.map((item) => (
            <li key={item._id} className="px-4 py-4 sm:px-6">
              <Checkins
                name={item.employee.name}
                email={item.employee.email}
                activity={item.activity}
                timestamp={item.timestamp}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default RecentActivity;

import { Outlet } from "react-router-dom";
import styled from "styled-components";
import SearchHeader from "../../Components/Header/SearchHeader";

function DashboardContainer() {
  return (
    <div>
      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <SearchHeader />
        </div>

        <Outlet />
      </div>
    </div>
  );
}

export default DashboardContainer;

const Container = styled.div`
  padding: 30px;
`;

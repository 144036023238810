import { useState } from "react";
import styled from "styled-components";
import PageHeader from "../../../Components/Header/PageHeader";
import CalendarSelector from "../../../Components/Selector/CalendarSelector";
import CalendarMonth from "../../../Containers/Calendar/CalendarMonth";
import CalendarWeek from "../../../Containers/Calendar/CalendarWeek";

function Calendar() {
  const [calendar, setCalendar] = useState<"MONTH" | "WEEK">("MONTH");

  return (
    <Container>
      <PageHeader title="Calendar" navStack={[]} />
      <CalendarSelector onSelect={(type) => setCalendar(type)} />
      {calendar === "MONTH" ? <CalendarMonth /> : <CalendarWeek />}
    </Container>
  );
}

export default Calendar;

const Container = styled.div`
  padding: 30px;
`;

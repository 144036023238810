import { combineReducers } from "redux";
import AuthReducer from "../Reducers/AuthReducer";
import UserReducer from "../Reducers/UserReducer";
import CompanyEntityReducer from "../Reducers/CompanyEntityReducer";

export const rootReducer = combineReducers({
  AuthReducer,
  UserReducer,
  CompanyEntityReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

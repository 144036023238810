import { useEffect, useState } from "react";
import styled from "styled-components";
import ChildHeader from "../../../Components/Header/ChildHeader";
import DateHeader from "../../../Components/Header/DateHeader";
import ExportStudentPDFModal from "../../../Components/Modals/ExportStudentPDFModal";
import { Separator } from "../../../Components/StyledComponents";
import AttendanceData from "../../../Containers/Attendance/AttendenceData";
import { MockAttendanceData } from "../../../db/MockAttendanceData";
import { Attendance, CalendarTypes, Student } from "../../../Types/DataTypes";
import { useApi } from "../../../utils/RequestUtils";
import { useAppSelector } from "../../../hooks/ReduxHooks";
import { useParams } from "react-router-dom";

function StudentInfo() {
  let { studentId } = useParams();
  const user = useAppSelector((state) => state.UserReducer);
  const [student, setStudent] = useState<Student | undefined>(undefined);
  const [modal, showModal] = useState<boolean>(false);
  const [calendarType, setCalendarType] = useState<CalendarTypes>("MONTH");
  const [weeks, setWeeks] = useState<number>(0);
  const [attendance, setAttendance] = useState<Attendance[]>([]);
  const { request } = useApi();

  useEffect(() => {
    switch (calendarType) {
      case "WEEK":
    }
  }, [calendarType]);

  useEffect(() => {
    request(`/user/${user.id}/student/${studentId}`, "GET").then((res) =>
      setStudent(res)
    );
    request(`/user/${user.id}/student/${studentId}/attendance`, "GET").then(
      (res) => setAttendance(res)
    );
  }, []);

  const onPrevious = () => {
    switch (calendarType) {
      case "MONTH":
        // setData(MockAttendanceData);
        break;
    }
    if (weeks >= 7 && calendarType !== "WEEK") {
      setWeeks(weeks - 7);
    }
  };
  const onNext = () => {
    if (weeks + 7 >= MockAttendanceData.length && calendarType === "MONTH")
      return;
    setWeeks(weeks + 7);
  };

  return (
    <Container>
      <ExportStudentPDFModal visible={modal} onClose={showModal} />
      <ChildHeader
        profilepic={student?.profilepic}
        name={student?.name!}
        parent={student?.primaryContact.name!}
        relation={student?.primaryContact.relation!}
        email={student?.primaryContact.email!}
        phone={student?.primaryContact.phoneNum!}
        caseNum={"#" + student?.caseNum}
        onExport={() => showModal(true)}
      />
      <Separator space={50} />
      <DateHeader
        title="Attendance Data"
        onPrevious={onPrevious}
        onNext={onNext}
        onCalendarSelect={(type) => setCalendarType(type)}
      />
      <AttendanceData data={attendance} calendarType={calendarType} />
    </Container>
  );
}

export default StudentInfo;

const Container = styled.div`
  padding: 30px;
`;

import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { CalendarTypes } from "../../Types/DataTypes";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface DateSelectorProps {
  onSelect: (type: CalendarTypes) => void;
}

function DateSelector(props: DateSelectorProps) {
  const [calendar, setCalendar] = useState<string>("Month View");
  const handleSelect = (type: CalendarTypes) => {
    switch (type) {
      case "DAY":
        setCalendar("Day View");
        break;
      case "WEEK":
        setCalendar("Week View");
        props.onSelect("WEEK");
        break;
      case "MONTH":
        setCalendar("Month View");
        props.onSelect("MONTH");
        break;
      case "YEAR":
        setCalendar("Year View");
        break;
    }
  };
  return (
    <div className="hidden md:ml-4 md:flex md:items-center">
      <Menu as="div" className="relative">
        <Menu.Button
          type="button"
          className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {calendar}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => handleSelect("DAY")}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Day view
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => handleSelect("WEEK")}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Week view
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => handleSelect("MONTH")}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Month view
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => handleSelect("YEAR")}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Year view
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default DateSelector;

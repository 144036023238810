export const getDateString = (epochTime: number): string => {
  const date = new Date(epochTime); // convert epoch time to milliseconds and create a new Date object
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  };
  const formattedDate = date
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");
  return formattedDate;
};

export const getDayOfWeek = (epochTime: number): string => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(epochTime); // convert epoch time to milliseconds and create a new Date object
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};

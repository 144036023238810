import LogoImg from "../../assets/Logos/Logo.png";
interface LogoProps {
  width: number;
  height: number;
}

function Logo(props: LogoProps) {
  const { width, height } = props;
  return <img src={LogoImg} width={width} height={height} />;
}

export default Logo;

import { createStore, compose, applyMiddleware } from "redux";
import {
  persistStore,
  persistReducer,
  PURGE,
  REGISTER,
  PERSIST,
  PAUSE,
  REHYDRATE,
  FLUSH,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

import { rootReducer } from "./Reducers/RootReducer";
import { configureStore } from "@reduxjs/toolkit";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["SignUpReducer", "UserDataReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
// let store = createStore(persistedReducer);
let persistor = persistStore(store);
// if (module.hot) {
//   module.hot.accept('../redux/reducers/index', () => {
//     // This fetch the new state of the above reducers.
//     const nextRootReducer = require('../redux/reducers/index').default;
//     store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
//   });
// }

export default { store, persistor };

export type AppDispatch = typeof store.dispatch;

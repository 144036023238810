import { Dispatch } from "@reduxjs/toolkit";
import { User } from "../../Types/DataTypes";
import { USER_LOGIN_SUCCESS, USER_LOGOUT } from "../types";

export const RefreshToken = (refreshToken: string) => {
  return (dispatch: Dispatch) => {};
};

export const SignIn = (
  accessToken: string,
  refreshToken: string,
  user: User,
  company: any
) => {
  return (dispatch: Dispatch) => {
    dispatch(
      onLoginSuccess({ ...user, accessToken, refreshToken, ...company })
    );
  };
};

export const SignOut = () => {
  return (dispatch: Dispatch) => {
    dispatch(onSignOutSuccess());
  };
};

const onLoginSuccess = (payload: any) => {
  console.log(payload);
  return {
    type: USER_LOGIN_SUCCESS,
    payload: payload,
  };
};

const onSignOutSuccess = () => {
  return {
    type: USER_LOGOUT,
  };
};

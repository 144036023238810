function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface AvatarProps {
  profilepic: string;
}

function Avatar(props: AvatarProps) {
  return (
    <img
      className={classNames("inline-block rounded-full", `h-24 w-24`)}
      src={props.profilepic}
      alt=""
    />
  );
}

export default Avatar;

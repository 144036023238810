import styled from "styled-components";
import { Theme } from "../Constants/Colors";
import {
  ButtonProps,
  HeaderProps,
  SeparatorProps,
} from "./StyledComponentTypes";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Theme.LIGHT.backgroundColor};
`;

export const Button = styled.button<ButtonProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const Page = styled.div`
  display: grid;
  flex: 1;
  align-items: center;
  background-color: transparent;
`;

export const Text = styled.span``;

export const Header = styled.p<HeaderProps>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
`;

export const Separator = styled.div<SeparatorProps>`
  display: flex;
  flex: 1;
  height: ${(props) => props.space}px;
`;

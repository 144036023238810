import { User } from "../../Types/DataTypes";
import { Action } from "../../Types/ReduxTypes";
import { USER_LOGIN_SUCCESS, USER_LOGOUT } from "../types";

const defaultState: User = {
  id: "",
  name: "",
  email: "",
  profilepic: "",
  entityName: "Best Daycare",
};

const userReducer = (state = defaultState, action: Action<User>) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return { ...state, ...MapToState(action.payload) };
    case USER_LOGOUT:
      return { ...defaultState };
    default:
      return state;
  }
};

export default userReducer;

function MapToState(payload: any): User {
  const user: User = {
    id: payload._id,
    name: payload.name,
    email: payload.email,
    profilepic: payload.profilepic,
    entityName: "Best Daycare",
  };
  return user;
}

import styled from "styled-components";
import PageHeader from "../../Components/Header/PageHeader";
import { Separator } from "../../Components/StyledComponents";
import RecentActivity from "../Dashboard/RecentActivity";
import StudentList from "../Students/StudentList";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { useEffect, useState } from "react";
import { EmployeeActivity, Student } from "../../Types/DataTypes";
import { useApi } from "../../utils/RequestUtils";

function DashboardHome() {
  const { request } = useApi();
  const user = useAppSelector((state) => state.UserReducer);
  const company = useAppSelector((state) => state.CompanyEntityReducer);
  const [students, setStudents] = useState<Student[]>([]);
  const [employeeActivity, setEmployeeActivity] = useState<EmployeeActivity[]>(
    []
  );

  useEffect(() => {
    request(`/user/${user.id}/activity/employee`, "GET").then((res) =>
      setEmployeeActivity(res)
    );
    request(`/user/${user.id}/student`, "GET").then((res) => {
      setStudents(res);
    });
  }, []);
  return (
    <Container>
      <PageHeader title={company.companyName} navStack={[]} />
      <RecentActivity items={employeeActivity} />
      <Separator space={50} />
      <StudentList students={students} />
    </Container>
  );
}

export default DashboardHome;

const Container = styled.div`
  padding: 30px;
`;

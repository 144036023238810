import { Action } from "../../Types/ReduxTypes";
import { USER_LOGIN_SUCCESS, USER_LOGOUT } from "../types";

interface CompanyEntity {
  id: string;
  companyName: string;
}

const defaultState: CompanyEntity = {
  id: "",
  companyName: "",
};

const companyEntityReducer = (
  state = defaultState,
  action: Action<CompanyEntity>
) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        companyName: action.payload.companyName,
      };
    case USER_LOGOUT:
      return { ...defaultState };
    default:
      return state;
  }
};

export default companyEntityReducer;

import styled from "styled-components";
import PageHeader from "../../../Components/Header/PageHeader";
import { Separator } from "../../../Components/StyledComponents";
import RecentActivity from "../../../Containers/Dashboard/RecentActivity";
import StudentList from "../../../Containers/Students/StudentList";

function Attendence() {
  return (
    <Container>
      <PageHeader title="Attendence" navStack={[]} />
    </Container>
  );
}

export default Attendence;

const Container = styled.div`
  padding: 30px;
`;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./Containers/Home/Home";
import StudentList from "./Containers/Students/StudentList";
import Attendence from "./Pages/Dashboard/Attendence/Attendence";
import Calendar from "./Pages/Dashboard/Calendar/Calendar";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Dashboard/Profile/Profile";
import AddStudent from "./Pages/Dashboard/Students/AddStudent";
import StudentInfo from "./Pages/Dashboard/Students/StudentInfo";
import Students from "./Pages/Dashboard/Students/Students";
import Landing from "./Pages/Landing/Landing";
import SignIn from "./Pages/SignUp/SignIn";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/signin",
      element: <SignIn />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        { index: true, element: <Home /> },
        {
          path: "attendence",
          element: <Attendence />,
        },
        {
          path: "student",

          children: [
            { index: true, element: <Students /> },
            {
              path: "add",
              element: <AddStudent />,
            },
          ],
        },
        {
          path: "student/:studentId",
          element: <StudentInfo />,
        },
        {
          path: "calendar",
          element: <Calendar />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;

import { useNavigate } from "react-router-dom";
import { Wrapper } from "../../Components/StyledComponents";
import DashboardContainer from "../../Containers/Dashboard/DashboardContainer";
import Sidebar from "../../Containers/Dashboard/Sidebar";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/ReduxHooks";

function Dashboard() {
  const navigate = useNavigate();
  const email = useAppSelector((state) => state.UserReducer.email);
  useEffect(() => {
    if (email === "") {
      navigate("/signin");
    }
  }, []);

  if (email === "") {
    return <></>;
  }

  return (
    <Wrapper>
      <Sidebar />
      <DashboardContainer />
    </Wrapper>
  );
}

export default Dashboard;

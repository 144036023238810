import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

interface SelectorProps {
  onPrevious: () => void;
  onNext: () => void;
}

function WeekSelector(props: SelectorProps) {
  return (
    <div className="flex flex-row items-center">
      <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch mr-4">
        <div
          className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
          aria-hidden="true"
        />
        <button
          onClick={props.onPrevious}
          type="button"
          className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          onClick={props.onNext}
          type="button"
          className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
        >
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <h4>1, April, 2022 - 8, April, 2022</h4>
    </div>
  );
}

export default WeekSelector;

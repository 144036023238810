/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { ChangeEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../utils/RequestUtils";
import InputWithLabel from "../../../Components/Input/Input";
import Selector from "../../../Components/Selector/Selector";
import { useAppSelector } from "../../../hooks/ReduxHooks";
import SuccessModal from "../../../Components/Modals/SuccessModal";

function AddStudent() {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.UserReducer);
  const company = useAppSelector((state) => state.CompanyEntityReducer);
  const [file, setFile] = useState<File>();
  const [profile, setProfile] = useState("");
  const [addSuccess, setAddSuccess] = useState<boolean>(false);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const dobRef = useRef<HTMLInputElement>(null);
  const studentTypeRef = useRef<string>("");
  const caseNumRef = useRef<string>("");
  const contactRelationRef = useRef<string>("");
  const contactDobRef = useRef<HTMLInputElement>(null);
  const contactName = useRef<HTMLInputElement>(null);
  const contactPhone = useRef<HTMLInputElement>(null);
  const contactEmail = useRef<HTMLInputElement>(null);
  const { request, setHeaders, setBody } = useApi();

  const fileToDataUri = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target?.result);
      };
      reader.readAsDataURL(file);
    });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      fileToDataUri(e.target.files[0]).then((res) => setProfile(res as string));
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file!);
    formData.append(
      "name",
      firstNameRef.current?.value + " " + lastNameRef.current?.value
    );
    formData.append("dob", Date.parse(dobRef.current?.value!).toString());
    formData.append("studentType", studentTypeRef.current);
    formData.append("caseNum", caseNumRef.current);
    formData.append("contactName", contactName.current?.value!);
    formData.append("contactEmail", contactEmail.current?.value!);
    formData.append("companyId", company.id);
    formData.append(
      "contactDob",
      Date.parse(contactDobRef.current?.value!).toString()
    );
    formData.append("contactRelation", contactRelationRef.current);
    formData.append("contactPhone", contactPhone.current?.value!);
    setHeaders({
      "Content-Type": "multipart/form-data",
    });
    setBody(formData);
    request(`/user/${user.id}/student/`, "POST").then(() =>
      setAddSuccess(true)
    );
  };

  return (
    <>
      <div>
        <SuccessModal
          visible={addSuccess}
          title="Success"
          message="Successfully added a student"
          onClose={(val) => {
            setAddSuccess(val);
            navigate(-1);
          }}
        />
        <div>
          <main>
            {/* Settings forms */}
            <div className="divide-y divide-white/5">
              {/* <Header className="ml-6 mt-10" fontSize={30} fontWeight="500">
                Add Student
              </Header> */}
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                {/* <div>
                  <h2 className="text-base font-semibold leading-7 text-black">
                    Onboard New Student
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Enter student details
                  </p>
                </div> */}

                <form
                  className="md:col-span-2"
                  onSubmit={handleSubmit}
                  action="/uploadphoto"
                  encType="multipart/form-data"
                  method="POST"
                >
                  <div className="grid grid-cols-2 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      <img
                        src={profile}
                        alt=""
                        className="h-24 w-24 flex-none rounded-full bg-gray-800 object-cover"
                      />
                      <div>
                        <label className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white/20">
                          Change avatar
                          <input
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                            accept="image/*"
                          />
                        </label>
                        <p className="mt-2 text-xs leading-5 text-gray-400">
                          JPG, GIF or PNG. 1MB max.
                        </p>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          ref={firstNameRef}
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="block w-full shadow-sm rounded-md border-1 border-gray-200 bg-white/5 py-1.5 text-black ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          ref={lastNameRef}
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-1 border-gray-200 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="dob"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Date of Birth
                      </label>
                      <div className="mt-2">
                        <input
                          ref={dobRef}
                          id="dob"
                          name="dob"
                          type="date"
                          autoComplete="email"
                          className="block w-full rounded-md border-1 border-gray-200 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <Selector
                      items={["Guest", "Student"]}
                      label="Student Type"
                      placeHolder="Guest"
                      onChange={(val) => (studentTypeRef.current = val)}
                    />
                    <InputWithLabel
                      onChange={(val) => (caseNumRef.current = val)}
                      label="Case Number"
                      error={"Enter Case Number"}
                      showError={false}
                      placeHolder={"0000-0000-0000"}
                      type={"text"}
                    />

                    <h2 className="text-base font-semibold leading-6 col-span-full">
                      Primary Contact
                    </h2>

                    <div className="col-span-full">
                      <label
                        htmlFor="parent-name"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Contact Name
                      </label>
                      <div className="mt-2">
                        <input
                          ref={contactName}
                          id="contact-name"
                          name="contact-name"
                          type="text"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-1 border-gray-200 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="parent-name"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Contact Email
                      </label>
                      <div className="mt-2">
                        <input
                          ref={contactEmail}
                          id="contact-email"
                          name="contact-email"
                          type="text"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-1 border-gray-200 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="dob"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Contact Date of Birth
                      </label>
                      <div className="mt-2">
                        <input
                          ref={contactDobRef}
                          id="contact-dob"
                          name="contact-dob"
                          type="date"
                          className="block w-full rounded-md border-1 border-gray-200 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <Selector
                      items={["Mother", "Father", "Brother", "Sister"]}
                      label="Relation"
                      placeHolder="Mother"
                      onChange={(val) => (contactRelationRef.current = val)}
                    />

                    <div className="col-span-full">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Phone Number
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          ref={contactPhone}
                          type="text"
                          name="phone-number"
                          id="phone-number"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="(555) 987-6543"
                        />
                      </div>
                    </div>

                    {/* <div className="col-span-full">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Username
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                          <span className="flex select-none items-center pl-3 text-gray-400 sm:text-sm">
                            @
                          </span>
                          <input
                            type="text"
                            name="username"
                            id="username"
                            autoComplete="username"
                            className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-black focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="janesmith"
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-span-full">
                      <label
                        htmlFor="timezone"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Timezone
                      </label>
                      <div className="mt-2">
                        <select
                          id="timezone"
                          name="timezone"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        >
                          <option>Pacific Standard Time</option>
                          <option>Eastern Standard Time</option>
                          <option>Greenwich Mean Time</option>
                        </select>
                      </div>
                    </div> */}
                  </div>

                  <div className="mt-8 flex">
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Add Student
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default AddStudent;

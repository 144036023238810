import { Action } from "../../Types/ReduxTypes";
import {
  AUTH_FAIL,
  AUTH_SUCCESS,
  USER_CREATED,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../types";

const defaultState: AuthState = {
  accessToken: "",
  refreshToken: "",
};

interface AuthState {
  accessToken: string;
  refreshToken: string;
}

const authReducer = (
  state = defaultState,
  action: Action<AuthState>
): AuthState => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return { ...state, ...action.payload };
    case AUTH_FAIL:
      return { ...state, ...action.payload };
    case USER_LOGIN_SUCCESS:
      return {
        accessToken: action.payload.accessToken!,
        refreshToken: action.payload.refreshToken!,
      };
    case USER_LOGOUT:
      return { ...defaultState };
    default:
      return state;
  }
};

export default authReducer;

import { RefreshToken, SignOut } from "../redux/Actions/AuthAction";
import { RootState } from "../redux/Reducers/RootReducer";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { useState, useEffect } from "react";
import { API_URL } from "../Constants/Env";
import axios, { AxiosError } from "axios";

type ResponseTypes = "json" | "blob";

export function useApi() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const dispatch = useAppDispatch();
  const token = useAppSelector(
    (state: RootState) => state.AuthReducer.accessToken
  );

  let body: object = {};
  let params: object = {};
  let headers: object = {};
  let responseType: ResponseTypes = "json";

  const setBody = (data: object) => (body = data);
  const setParams = (data: object) => (params = data);
  const setHeaders = (data: object) => (headers = data);
  const setResponseType = (type: ResponseTypes) => (responseType = type);
  //   options.headers = {
  //     ...options.headers,
  //     Authorization: `Bearer ${token}`,
  //   };

  useEffect(() => {});

  const request = async (url: string, method: "POST" | "GET" | "PUT") => {
    try {
      const response = await axios(API_URL + url, {
        method: method,
        data: body,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        responseType: responseType,
      });
      return response.data;
    } catch (err) {
      console.log(err);
      const error = err as AxiosError;

      if (error.status === 401) {
        // Token expired, attempt to refresh the token
        const refreshResponse = await fetch(
          process.env.API_URL + url + "/refresh-token"
        );

        if (refreshResponse.ok) {
          // Token refreshed successfully, update the token in the store
          const refreshedToken = await refreshResponse.json();
          dispatch(RefreshToken(refreshedToken));

          // Retry the original request with the updated token
          //   options.headers = {
          //     ...options.headers,
          //     Authorization: `Bearer ${refreshedToken}`,
          //   };
          return fetch(url);
        } else {
          // Token refresh failed, logout the user
          dispatch(SignOut());
          // You can also redirect the user to the login page if needed
          // e.g., history.push('/login');
        }
      }

      console.log(error.response?.data);
      setError(error.response?.data!);

      throw err;
    }
  };

  return {
    request,
    isLoading,
    error,
    setResponseType,
    setHeaders,
    setBody,
    setParams,
    body,
  };
}

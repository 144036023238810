function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface AttendanceStatusProps {
  status: "PRESENT" | "ABSENT" | "HOLIDAY" | "LATE";
}

function AttendanceStatus(props: AttendanceStatusProps) {
  const statusMap = {
    PRESENT: {
      text: "Present",
      style: "bg-green-100 text-green-80",
    },
    ABSENT: {
      text: "Absent",
      style: "bg-red-100 text-red-800",
    },
    HOLIDAY: {
      text: "Holiday",
      style: "bg-yellow-100 text-yellow-800",
    },
    LATE: {
      text: "Late",
      style: "bg-purple-100 text-purple-800",
    },
  };
  return (
    <span
      className={classNames(
        "inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium",
        statusMap[`${props.status}`].style
      )}
    >
      {statusMap[`${props.status}`].text}
    </span>
  );
}

export default AttendanceStatus;

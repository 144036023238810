export const Theme: { LIGHT: ThemeType; DARK: ThemeType } = {
  LIGHT: {
    backgroundColor: "white",
    card: "",
    text: "",
  },
  DARK: {
    backgroundColor: "",
    card: "",
    text: "",
  },
};

type ThemeType = {
  backgroundColor: string;
  card: string;
  text: string;
};

import PrimaryFeatures from "./Sections/Features";
import GetStarted from "./Sections/GetStarted";
import HeroSection from "./Sections/HeroSection";
import StatsSection from "./Sections/Stats";
import TeamSection from "./Sections/TeamSection";
import TestimonialSection from "./Sections/TestimonialSection";

function Landing() {
  return (
    <>
      <HeroSection />
      <PrimaryFeatures />
      <GetStarted />
    </>
  );
}

export default Landing;

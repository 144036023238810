import { ChevronRightIcon } from "@heroicons/react/20/solid";
import CheckinStatus from "./CheckinStatus";

interface CheckInProps {
  name: string;
  email: string;
  activity: string;
  timestamp: number;
}

function Checkins(props: CheckInProps) {
  return (
    <div className="flex flex-row flex-1 h-16 items-center">
      <img
        className="inline-block h-12 w-12 rounded-full mr-4"
        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        alt=""
      />
      <div className="flex-1 grid grid-cols-2 h-12">
        <div className="min-w-0 flex-1">
          <a href="#" className="focus:outline-none">
            <p className="text-sm font-medium text-gray-900 mb-2">
              {props.name}
            </p>
            <p className="truncate text-sm text-gray-500">{props.email}</p>
          </a>
        </div>
        <div className="min-w-0 flex-1 ">
          <a href="#" className="focus:outline-none">
            <p className="text-sm font-medium text-gray-900 p-0 mb-2">
              January 10, 2022
            </p>
            <CheckinStatus />
          </a>
        </div>
      </div>
      <ChevronRightIcon
        className="h-6 w-6 flex-shrink-0 text-gray-400 mr-4 "
        aria-hidden="true"
      />
    </div>
  );
}

export default Checkins;

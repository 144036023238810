import { CheckCircleIcon } from "@heroicons/react/20/solid";

function CheckinStatus() {
  return (
    <div className="flex flex-row">
      <CheckCircleIcon className="w-5 h-5 text-green-500" />
      <p className="truncate text-sm text-gray-500">Checkin Time</p>
    </div>
  );
}

export default CheckinStatus;

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

interface InputWithLabelProps {
  label: string;
  error: string;
  showError: boolean;
  placeHolder: string;
  type: "email" | "text";
  id?: string;
  onChange: (val: string) => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function InputWithLabel(props: InputWithLabelProps) {
  return (
    <div className="col-span-full">
      <label
        htmlFor={props.label.toLowerCase()}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type={props.type}
          name={props.label}
          id={props.id}
          onChange={(val) => props.onChange(val.target.value)}
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6",
            props.showError
              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
              : ""
          )}
          placeholder={props.placeHolder}
          aria-invalid="true"
          aria-describedby="email-error"
        />
        {props.showError ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {props.showError ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.error}
        </p>
      ) : null}
    </div>
  );
}

import { CalendarTypes } from "../../Types/DataTypes";
import DateSelector from "../Selector/DateSelector";
import WeekSelector from "../Selector/WeekSelector";

interface DateHeaderProps {
  title: string;
  onPrevious: () => void;
  onNext: () => void;
  onCalendarSelect: (type: CalendarTypes) => void;
}

function DateHeader(props: DateHeaderProps) {
  return (
    <div className="flex lg:flex-row flex-col">
      <h2 className=" text-2xl mr-6">{props.title}</h2>
      <div className="flex flex-1 flex-row justify-between">
        <WeekSelector {...props} />
        <DateSelector onSelect={props.onCalendarSelect} />
      </div>
    </div>
  );
}

export default DateHeader;

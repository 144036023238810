export const MockAttendanceData = [
  { date: 1617235200, day: "FRIDAY", hours: 8, status: "PRESENT" },
  { date: 1617321600, day: "SATURDAY", hours: 4, status: "LATE" },
  { date: 1617408000, day: "SUNDAY", hours: 0, status: "ABSENT" },
  { date: 1617494400, day: "MONDAY", hours: 9, status: "PRESENT" },
  { date: 1617580800, day: "TUESDAY", hours: 8, status: "PRESENT" },
  { date: 1617667200, day: "WEDNESDAY", hours: 7, status: "LATE" },
  { date: 1617753600, day: "THURSDAY", hours: 0, status: "ABSENT" },
  { date: 1617840000, day: "FRIDAY", hours: 8, status: "PRESENT" },
  { date: 1617926400, day: "SATURDAY", hours: 4, status: "LATE" },
  { date: 1618012800, day: "SUNDAY", hours: 0, status: "ABSENT" },
  { date: 1618099200, day: "MONDAY", hours: 9, status: "PRESENT" },
  { date: 1618185600, day: "TUESDAY", hours: 8, status: "PRESENT" },
  { date: 1618272000, day: "WEDNESDAY", hours: 7, status: "LATE" },
  { date: 1618358400, day: "THURSDAY", hours: 0, status: "ABSENT" },
  { date: 1618444800, day: "FRIDAY", hours: 8, status: "PRESENT" },
  { date: 1618531200, day: "SATURDAY", hours: 4, status: "LATE" },
  { date: 1618617600, day: "SUNDAY", hours: 0, status: "ABSENT" },
  { date: 1618704000, day: "MONDAY", hours: 9, status: "PRESENT" },
  { date: 1618790400, day: "TUESDAY", hours: 8, status: "PRESENT" },
  { date: 1618876800, day: "WEDNESDAY", hours: 7, status: "LATE" },
  { date: 1618963200, day: "THURSDAY", hours: 0, status: "ABSENT" },
  { date: 1619049600, day: "FRIDAY", hours: 8, status: "PRESENT" },
  { date: 1619136000, day: "SATURDAY", hours: 4, status: "LATE" },
  { date: 1619222400, day: "SUNDAY", hours: 0, status: "ABSENT" },
  { date: 1619308800, day: "MONDAY", hours: 9, status: "PRESENT" },

  { date: 1685097600, day: "TUESDAY", hours: 8, status: "PRESENT" },
  { date: 1685184000, day: "WEDNESDAY", hours: 7, status: "LATE" },
  { date: 1685270400, day: "THURSDAY", hours: 0, status: "ABSENT" },
  { date: 1685356800, day: "FRIDAY", hours: 8, status: "PRESENT" },
  { date: 1685443200, day: "SATURDAY", hours: 4, status: "LATE" },
  { date: 1685529600, day: "SUNDAY", hours: 0, status: "ABSENT" },
  { date: 1685616000, day: "MONDAY", hours: 9, status: "PRESENT" },
];
